import ApiClient from './ApiClient';

class CustomerService {
    constructor(apiClient) {
        this.apiClient = apiClient;
    }

    async getCustomers(offset, limit, search) {
        return await this.apiClient.get('admin/customer', {
            offset: offset,
            limit: limit,
            search: search,
        }, {
            offset: 0,
            limit: 50,
            search: '',
        });
    }

    async editCustomer(id, customer) {
        return await this.apiClient.post('admin/customer/' + id, null, customer);
    }
}

export default new CustomerService(ApiClient);