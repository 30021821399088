import React, {useState} from 'react';
import {
    Button,
    Form,
} from 'react-bootstrap';
import OtherPage from '../OtherPage';
import {FormattedMessage,injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import LoginService from '../../services/LoginService';
import {NavLink} from 'react-router-dom';

function ForgotPasswordPage({ intl }) {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const resetPassword = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (email.length < 1) {
            return;
        }

        setSubmitted(true);

        try {
            const result = await LoginService.resetPassword(
                email
            );

            if (!result.success) {
                setSubmitted(false);
                setError(intl.formatMessage({id: 'somethingWentWrong'}));
                return;
            }

            setSuccess(true);
        } catch {
            setSubmitted(false);
            setError(intl.formatMessage({id: 'somethingWentWrong'}));
        }
    };

    return (
        <OtherPage name='forgotPassword'>
            { success ?
                <p>
                    <FormattedMessage id='successfulReset'/>
                </p> : <div>
                    <Form onSubmit={resetPassword}>
                        <Form.Group className="mb-3">
                            <Form.Control type='email'
                                          placeholder={intl.formatMessage({id: 'customers.email'})}
                                          value={email}
                                          onChange={(e) => { setEmail(e.target.value); setError(false); } }
                                          disabled={submitted}
                            />
                        </Form.Group>

                        <Button type='submit'
                                variant='success'
                                className='mt-2 w-100'
                                disabled={submitted}
                        >
                            <FormattedMessage id='nav.forgotPassword'/>
                        </Button>

                        { error ? (<p className='text-danger'>{error}</p>) : null }
                    </Form>

                    <NavLink to='/login' className='mt-2 d-block text-decoration-none'>
                        <FormattedMessage id='nav.login'/>
                    </NavLink>
                </div>
            }
        </OtherPage>
    );
}

ForgotPasswordPage.propTypes = {
    intl: PropTypes.object.isRequired
}

export default injectIntl(ForgotPasswordPage);
