import React from 'react';
import DashboardPage from '../DashboardPage';

function LessonPage() {
    return (
        <DashboardPage name='lessons'>
        </DashboardPage>
    );
}

export default LessonPage;
