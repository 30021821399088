import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormattedMessage} from 'react-intl';
import {NavLink} from 'react-router-dom';

function MainNavLink({ icon, textId, link }) {
    return (
        <NavLink
            to={link}
            className='nav-link'
        >
            <FontAwesomeIcon icon={icon} className='nav-icon'/>
            <span className='d-inline-block'>
                <FormattedMessage id={textId}/>
            </span>
        </NavLink>
    );
}

MainNavLink.propTypes = {
    icon: PropTypes.object.isRequired,
    textId: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};

export default MainNavLink;