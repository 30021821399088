import React, {useEffect, useState} from 'react';
import {
    Col,
    Container,
    Nav,
    Navbar,
    Offcanvas,
    Row,
} from 'react-bootstrap';
import logo from '../logo.svg';
import MainNavLink from '../components/MainNavLink';
import {
    faChartColumn,
    faPersonChalkboard, faUser, faUsers, faSignOut
} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage} from 'react-intl';
import LoginService from '../services/LoginService';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useNavigate} from 'react-router-dom';
import ApiClient from '../services/ApiClient';

function DashboardPage(props) {
    const [user, setUser] = useState(null);
    const [loggingOut, setLoggingOut] = useState(false);
    const navigate = useNavigate();
    ApiClient.setNavigate(navigate);

    const getUser = async () => {
        setUser(await LoginService.getUser());
    };

    useEffect(function () {
        if (user) {
            return;
        }

        getUser();
    });

    const logout = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setLoggingOut(true);

        try {
            await LoginService.logout();
        } catch {
        }

        navigate('/login');
    };

    return (
        <div>
            <Navbar bg="dark" variant="dark" sticky="top"
                    className="mb-3" expand='lg'>
                <Container>
                    <Navbar.Brand className="pt-0">
                        <img
                            alt=""
                            src={logo}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />
                        <span className='d-inline-block'>
                            {' Salsa Familia'}
                        </span>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls='navbar-expand'/>

                    <Navbar.Offcanvas
                        id='navbar-expand'
                        aria-labelledby='navbar-expand-label'
                        placement='end'
                        className='bg-dark'
                    >
                        <Offcanvas.Header closeButton={true} closeVariant='white'>
                            <Offcanvas.Title id='navbar-expand-label'>
                                <img
                                    alt=""
                                    src={logo}
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-top"
                                />
                                <span className='d-inline-block'>
                                    {' Salsa Familia'}
                                </span>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="me-auto">
                                <MainNavLink
                                    textId='nav.overview'
                                    icon={faChartColumn}
                                    link={'/'}
                                />

                                <MainNavLink
                                    textId='nav.lessons'
                                    icon={faPersonChalkboard}
                                    link={'/lessons'}
                                />

                                <MainNavLink
                                    textId='nav.customers'
                                    icon={faUser}
                                    link={'/customers'}
                                />

                                <MainNavLink
                                    textId='nav.groups'
                                    icon={faUsers}
                                    link={'/groups'}
                                />
                            </Nav>

                            <hr/>

                            <Nav className='justify-content-end'>
                                <Nav.Item>
                                    <Nav.Link onClick={logout}>
                                        <FontAwesomeIcon icon={faSignOut} className='nav-icon'/>
                                        <span className='d-inline-block'>
                                            {
                                                !loggingOut ?
                                                <FormattedMessage id='nav.logout'/> : <FormattedMessage id='nav.loggingOut'/>
                                            }
                                        </span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

            <Container fluid='md'>
                <Row>
                    <Col xs={12}>
                        <h4>
                            <FormattedMessage id={'nav.' + props.name}/>
                        </h4>

                        { props.children }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DashboardPage;
