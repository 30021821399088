import React, {useState} from 'react';
import {
    Button,
    Form,
} from 'react-bootstrap';
import OtherPage from '../OtherPage';
import {FormattedMessage,injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import LoginService from '../../services/LoginService';
import {NavLink, useNavigate} from 'react-router-dom';

function LoginPage({ intl }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const login = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (password.length < 1 || email.length < 1) {
            setError(intl.formatMessage({id: 'badCredentials'}));
            return;
        }

        setSubmitted(true);

        try {
            const result = await LoginService.login(
                email,
                password,
                rememberMe
            );

            if (!result.success) {
                setPassword('');
                setSubmitted(false);
                setError(intl.formatMessage({id: 'badCredentials'}));
                return;
            }

            navigate('/');
        } catch {
            setPassword('');
            setSubmitted(false);
            setError(intl.formatMessage({id: 'badCredentials'}));
        }
    };

    return (
        <OtherPage name='login'>
            <Form onSubmit={login}>
                <Form.Group className="mb-3">
                    <Form.Control type='email'
                                  placeholder={intl.formatMessage({id: 'customers.email'})}
                                  value={email}
                                  onChange={(e) => { setEmail(e.target.value); setError(false); } }
                                  disabled={submitted}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control type='password'
                                  placeholder={intl.formatMessage({id: 'password'})}
                                  value={password}
                                  onChange={(e) => { setPassword(e.target.value); setError(false); } }
                                  disabled={submitted}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Check type='checkbox'
                                label={intl.formatMessage({id: 'rememberMe'})}
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(!rememberMe) }
                    />
                </Form.Group>

                <Button type='submit'
                        variant='success'
                        className='mt-2 w-100'
                        disabled={submitted}
                >
                    <FormattedMessage id='nav.login'/>
                </Button>

                { error ? (<p className='text-danger'>{error}</p>) : null }
            </Form>
            <NavLink to='/reset-password' className='mt-2 d-block text-decoration-none'>
                <FormattedMessage id='forgotPassword'/>
            </NavLink>
        </OtherPage>
    );
}

LoginPage.propTypes = {
    intl: PropTypes.object.isRequired
}

export default injectIntl(LoginPage);
