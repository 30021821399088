import React from 'react';
import DashboardPage from '../DashboardPage';

function HomePage() {
    return (
        <DashboardPage name='overview'>
        </DashboardPage>
    );
}

export default HomePage;
