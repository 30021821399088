import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';
import {
    FormattedDate,
    FormattedMessage,
    FormattedNumber,
} from 'react-intl';

function FieldValue({name, type, value, editing, onChange}) {
    let displayValue, editValue;
    onChange = onChange ? onChange : () => {};

    if (type === 'text') {
        displayValue = value ? value : <FormattedMessage id='field.na'/>;
        editValue = <Form.Control type='text' value={value ? value : ''} onChange={(e) => onChange(e.target.value)}/>;
    } else if (type === 'currency') {
        // eslint-disable-next-line
        displayValue = value ? <FormattedNumber value={value} style="currency" currency="RSD" /> : <FormattedMessage id='field.na'/>;
    } else if (type === 'email') {
        displayValue = value ? value : <FormattedMessage id='field.na'/>;
        editValue = <Form.Control type='email' value={value ? value : ''} onChange={(e) => onChange(e.target.value)}/>;
    } else if (type === 'tel') {
        displayValue = value ? value : <FormattedMessage id='field.na'/>;
        editValue = <Form.Control type='tel' value={value ? value : ''} onChange={(e) => onChange(e.target.value)}/>;
    } else if (type === 'date') {
        displayValue = value ? <FormattedDate
            value={value}
            year='numeric'
            month="2-digit"
            day="2-digit"
        /> : <FormattedMessage id='field.na'/>;
        editValue = <Form.Control type='date' value={value ? value : ''} onChange={(e) => onChange(e.target.value)}/>;
    } else if (type === 'gender') {
        displayValue = value === 'male' ? <FormattedMessage id='gender.male'/> : <FormattedMessage id='gender.female'/>;
        editValue = <Form.Select value={value ? value : ''} onChange={(e) => onChange(e.target.value)}>
            <option value='male'><FormattedMessage id='gender.male'/></option>
            <option value='female'><FormattedMessage id='gender.female'/></option>
        </Form.Select>;
    }

    return (<div className='mt-2'>
        <div style={{ width: '135px' }} className='d-inline-block'>
            {name}:
        </div>
        <div style={{ width: '155px' }} className='d-inline-block fw-bold'>
            {editing ? editValue : displayValue}
        </div>
    </div>);
}

FieldValue.propTypes = {
    name: PropTypes.node.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any,
    editing: PropTypes.bool,
};

export default FieldValue;