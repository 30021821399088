import React from 'react';
import DashboardPage from '../DashboardPage';

function GroupPage() {
    return (
        <DashboardPage name='groups'>
        </DashboardPage>
    );
}

export default GroupPage;
