import React from 'react';
import { Pagination } from 'react-bootstrap';
import PropTypes from 'prop-types';

function PagePagination({ totalPages, currentPage, onChange, disabled, toShow }) {
    toShow = toShow ? toShow : 5;
    const paginationItems = [];

    const startPage = Math.max(Math.ceil(currentPage - toShow / 2), 1);
    const xsShow = Math.min(toShow, 5);
    const xsStart = Math.max(Math.ceil(currentPage - xsShow / 2), 1);

    for (let pageNum = startPage; pageNum < startPage + toShow && pageNum <= totalPages; ++pageNum) {
        if (pageNum === currentPage) {
            paginationItems.push(
                <Pagination.Item key={pageNum} active>
                    {pageNum}
                </Pagination.Item>
            );
        } else {
            const classNames = xsStart > pageNum || pageNum >= xsStart + xsShow ? 'd-none d-sm-block' : '';
            paginationItems.push(
                <Pagination.Item
                    className={classNames}
                    key={pageNum}
                    disabled={disabled}
                    onClick={() => { if (onChange) { onChange(pageNum) } }}
                >
                    {pageNum}
                </Pagination.Item>
            );
        }
    }

    return (
        <Pagination className='page-pagination'>
            { paginationItems }
        </Pagination>
    );
}

PagePagination.propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    toShow: PropTypes.number,
};

export default PagePagination;