import React, {useState} from 'react';
import {
    Form
} from 'react-bootstrap';
import CustomerService from '../../services/CustomerService';
import {FormattedMessage,injectIntl} from 'react-intl';
import AccordionList from '../../components/AccordionList';
import PropTypes from 'prop-types';
import CustomerOverview from '../../components/CustomerOverview';
import DashboardPage from '../DashboardPage';

function CustomerPage({ intl }) {
    const [search, setSearch] = useState('');

    return (
        <DashboardPage name='customers'>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label><FormattedMessage id='customers.search'/></Form.Label>
                    <Form.Control placeholder={intl.formatMessage({id: 'customers.search'}) + '...'} onChange={ (e) => { setSearch(e.target.value); } } />
                </Form.Group>
            </Form>

            <AccordionList
                getList={async (offset, limit, searchCriteria) => {
                    return await CustomerService.getCustomers(
                        offset,
                        limit,
                        searchCriteria
                    );
                }}
                getItemName={(customer) => customer.name}
                getItemContent={(customer) => <CustomerOverview customer={customer}/>}
                searchCriteria={search}
                itemsPerPage={10}
                searchInfoMessageId='customers.results'
            />
        </DashboardPage>
    );
}

CustomerPage.propTypes = {
    intl: PropTypes.object.isRequired
}

export default injectIntl(CustomerPage);