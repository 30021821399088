class ApiClient {
    constructor(baseUrl) {
        this.cache = {};
        this.baseUrl = baseUrl;
        this.navigate = (url) => {
            window.location.href = url;
        };
    }

    setNavigate(navigate) {
        this.navigate = navigate;
    }

    async get(path, params, defaults, cacheTime) {
        const self = this;
        let fullPath = this.baseUrl + '/' + path;

        if (params) {
            if (defaults) {
                for (const key in defaults) {
                    if (!(key in params)) {
                        params[key] = defaults[key];
                    }
                }
            }

            fullPath += '?' + (new URLSearchParams(params)).toString();
        }

        if (!self.cache[fullPath]) {
            self.cache[fullPath] = fetch(fullPath, {
                method: 'GET',
                credentials: 'include'
            });

            setTimeout(function () {
                delete self.cache[fullPath];
            }, cacheTime ? cacheTime : 1000 * 60 * 5);
        }

        const response = await self.cache[fullPath];

        if (response.status !== 200) {
            delete self.cache[fullPath];
        }

        if (response.status === 403) {
            self.navigate('/login');
            throw new Error('Not logged in!');
        }

        return await response.clone().json();
    }

    async post(path, params, data) {
        const self = this;
        let fullPath = this.baseUrl + '/' + path;

        if (params) {
            fullPath += '?' + (new URLSearchParams(params)).toString();
        }

        const response = await fetch(fullPath, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data ? JSON.stringify(data) : ''
        });

        if (response.status === 403) {
            self.navigate('/login');
            throw new Error('Not logged in!');
        }

        return response.clone().json();
    }
}

export default new ApiClient(window.location.host.indexOf('localhost') !== -1 ?
    'http://localhost:8080' : 'https://api.skolasalse.com');