import PropTypes from 'prop-types';
import {
    FormattedMessage,
} from 'react-intl';
import {Button, Col, Row} from 'react-bootstrap';
import FieldValue from './FieldValue';
import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil} from '@fortawesome/free-solid-svg-icons';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {faX} from '@fortawesome/free-solid-svg-icons';
import {Form} from 'react-bootstrap';
import CustomerService from '../services/CustomerService';

function CustomerOverview({customer}) {
    const [ editing, setEditing ] = useState(false);
    const [ current, setCurrent ] = useState({...customer});
    const [ prev, setPrev ] = useState({...customer});
    const paidPct = Math.round(parseInt(customer.paidMonths) / parseInt(customer.activeMonths) * 100);

    const cancelEdit = () => {
        setEditing(!editing);
        setCurrent({
            ...prev
        });
    };

    const editClick = async (e) => {
        e.preventDefault();
        if (!editing) {
            setPrev({...current});
            setEditing(!editing);
        } else {
            setEditing(!editing);
            try {
                await CustomerService.editCustomer(customer.id, {
                    name: current.name,
                    gender: current.gender,
                    dateOfBirth: current.dateOfBirth,
                    phoneNumber: current.phoneNumber,
                    email: current.email,
                });
            } catch {
                setCurrent({
                    ...prev
                });
            }
        }
    };

    const getField = (fieldName, type) => {
        return <FieldValue
            name={<FormattedMessage id={'customers.' + fieldName}/>}
            type={type}
            value={current[fieldName]}
            editing={editing}
            onChange={(val) => setCurrent({...current, [fieldName]: val})}
        />;
    }

    return (<Row>
        <Col xs={12} md={6} lg={4}>
            <Form onSubmit={editClick}>
                <h5 className='text-primary mb-2 d-inline-block'><FormattedMessage id='customers.generalInfo'/></h5>
                <Button type='submit' variant={editing ? 'success' : 'secondary'}
                        size='sm'
                        className='ms-3 d-inline-block'
                >
                    {
                        editing ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faPencil}/>
                    }
                </Button>
                {
                    editing ?
                        <Button type='button' variant='danger'
                                size='sm'
                                className='ms-1 d-inline-block'
                                onClick={cancelEdit}>
                            <FontAwesomeIcon icon={faX}/>
                        </Button> : null
                }

                {getField('name', 'text')}
                {getField('gender', 'gender')}
                {getField('dateOfBirth', 'date')}
                {getField('phoneNumber', 'tel')}
                {getField('email', 'email')}
            </Form>
        </Col>

        <Col xs={12} md={6} lg={4} className='mt-4 mt-md-0'>
            <h5 className='text-primary mb-2 d-inline-block'><FormattedMessage id='customers.stats'/></h5>
            <FieldValue
                name={<FormattedMessage id='customers.memberSince'/>}
                type='date'
                value={customer.joinedAt}
            />

            <FieldValue
                name={<FormattedMessage id='customers.paidMonths'/>}
                type='text'
                value={customer.paidMonths + '/' + customer.activeMonths + ' (' + paidPct + '%)'}
            />

            <FieldValue
                name={<FormattedMessage id='customers.paid'/>}
                type='currency'
                value={customer.paid}
            />

            <FieldValue
                name={<FormattedMessage id='customers.lessonsAttended'/>}
                type='text'
                value={customer.lessonsAttended}
            />

            <FieldValue
                name={<FormattedMessage id='customers.averageLessonCost'/>}
                type='currency'
                value={customer.averageLessonCost}
            />
        </Col>
    </Row>);
}

CustomerOverview.propTypes = {
    customer: PropTypes.object.isRequired,
};

export default CustomerOverview;