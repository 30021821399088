import en from './languages/en.json';
import sr from './languages/sr.json';

const messages = {
  en: en,
  sr: sr,
};

const locale = 'sr'; //navigator.language.split(/[-_]/)[0];
const translations = locale in messages ? messages[locale] : messages['en'];

export { locale };
export { translations };