import {
    Accordion,
    Spinner,
} from 'react-bootstrap';
import PagePagination from './PagePagination';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

function AccordionList({ itemsPerPage, getList, getItemId, getItemName, getItemContent, searchCriteria, searchInfoMessageId }) {
    itemsPerPage = itemsPerPage ? itemsPerPage : 20;
    getItemId = getItemId ? getItemId : (item) => item.id;

    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [criteriaChange, setCriteriaChange] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);
    const [error, setError] = useState(false);

    const refreshList = async () => {
        try {
            const response = await getList(
                offset,
                itemsPerPage,
                searchCriteria
            );

            if (criteriaChange) {
                setOffset(0);
            }

            setTotal(response.total);
            setList(response.result);
            setLoading(false);
            setCriteriaChange(false);
            setInitialLoad(false);
        } catch {
            setError(true);
        }
    };

    useEffect(function () {
        setLoading(true);
        setCriteriaChange(true);
    }, [searchCriteria]);

    useEffect(function () {
        if (!loading) {
            return;
        }

        refreshList();
    });

    if (error) {
        return (
            <div>
                <p><FormattedMessage id='somethingWentWrong'/></p>
            </div>
        );
    }

    const totalPages = Math.ceil(total / itemsPerPage);
    const currentPage = Math.floor(offset / itemsPerPage) + 1;

    return (
        <div>
            {
                !initialLoad && total > 0 && searchInfoMessageId ?
                    <p>
                        <FormattedMessage
                            id={searchInfoMessageId}
                            values={{
                                number: list.length,
                                total: total,
                            }}
                        />
                    </p> : null
            }

            { initialLoad || (loading && total === 0) ? <Spinner animation='border' /> : null }
            { !initialLoad ? <Accordion className={'mb-3' + (loading ? ' loading' : '')}>
                {
                    list.map((listItem) => {
                        return (
                            <Accordion.Item eventKey={getItemId(listItem)} key={getItemId(listItem)}>
                                <Accordion.Header>{getItemName(listItem)}</Accordion.Header>
                                <Accordion.Body>{getItemContent(listItem)}</Accordion.Body>
                            </Accordion.Item>
                        );
                    })
                }
            </Accordion> : null
            }

            {
                !initialLoad && total === 0 && !loading ?
                    <p><FormattedMessage id='search.empty'/></p> : null
            }

            { total > 0 ? <PagePagination
                totalPages={totalPages}
                currentPage={currentPage}
                disabled={loading}
                onChange={(page) => {
                    setOffset(itemsPerPage * (page - 1));
                    setLoading(true);
                }}
                toShow={10}
            /> : null}
        </div>
    );
}

AccordionList.propTypes = {
    itemsPerPage: PropTypes.number,
    getList: PropTypes.func.isRequired,
    getItemId: PropTypes.func,
    getItemName: PropTypes.func.isRequired,
    getItemContent: PropTypes.func.isRequired,
    searchInfoMessageId: PropTypes.string,
    searchCriteria: PropTypes.any
};

export default AccordionList;