import React from 'react';
import {Col, Container, Navbar, Row} from 'react-bootstrap';
import logo from '../logo.svg';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import ApiClient from '../services/ApiClient';

function OtherPage(props) {
    const navigate = useNavigate();
    ApiClient.setNavigate(navigate);

    return (
        <div>
            <Navbar bg="dark" variant="dark" sticky="top"
                    className="mb-3">
                <Container fluid='md' className='justify-content-center'>
                    <Navbar.Brand className="pt-0">
                        <img
                            alt=""
                            src={logo}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />
                        <span className='d-inline-block'>
                            {' Salsa Familia'}
                        </span>
                    </Navbar.Brand>
                </Container>
            </Navbar>

            <Container fluid="xl">
                <Row>
                    <Col
                        xxl={{ span: 4, offset: 4 }}
                        xl={{ span: 4, offset: 4 }}
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        xs={{ span: 10, offset: 1 }}
                    >
                        <h4 className='mb-3'>
                            <FormattedMessage id={'nav.' + props.name}/>
                        </h4>

                        { props.children }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default OtherPage;
