import ApiClient from './ApiClient';

class LoginService {
    constructor(apiClient) {
        this.apiClient = apiClient;
    }

    async setPassword(email, token, password) {
        return await this.apiClient.post('login/password', null, {
            email: email,
            token: token,
            password: password,
        });
    }

    async login(email, password, rememberMe) {
        return await this.apiClient.post('login', null, {
            email: email,
            password: password,
            rememberMe: !!rememberMe,
        });
    }

    async resetPassword(email) {
        return await this.apiClient.post('login/reset', null, {
            email: email
        });
    }

    async logout() {
        return await this.apiClient.post('login/logout');
    }

    async getUser() {
        return await this.apiClient.get('admin/user');
    }
}

export default new LoginService(ApiClient);