import React, {useState} from 'react';
import {
    Button,
    Form,
} from 'react-bootstrap';
import OtherPage from '../OtherPage';
import {FormattedMessage,injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {useNavigate, useSearchParams} from 'react-router-dom';
import LoginService from '../../services/LoginService';

function PasswordPage({ intl }) {
    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState('');
    const [repeat, setRepeat] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const resetPassword = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSubmitted(true);

        try {
            const result = await LoginService.setPassword(
                searchParams.get('email'),
                searchParams.get('token'),
                password
            );

            if (!result.success) {
                setSubmitted(false);
                setError(intl.formatMessage({id: 'failedSetPassword'}));
                return;
            }

            navigate('/');
        } catch {
            setPassword('');
            setRepeat('');
            setSubmitted(false);
            setError(intl.formatMessage({id: 'failedSetPassword'}));
        }
    };

    return (
        <OtherPage name='createPassword'>
            <Form onSubmit={resetPassword}>
                <Form.Group className="mb-3">
                    <Form.Control disabled={true} placeholder={searchParams.get('email')}/>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control type='password'
                                  placeholder={intl.formatMessage({id: 'password'})}
                                  value={password}
                                  onChange={(e) => { setPassword(e.target.value); setError(false); } }
                                  disabled={submitted}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control type='password'
                                  placeholder={intl.formatMessage({id: 'passwordRepeat'})}
                                  value={repeat}
                                  onChange={(e) => { setRepeat(e.target.value); setError(false); }}
                                  disabled={submitted}
                    />
                </Form.Group>

                <Button type='submit'
                        variant='success'
                        className='mt-2 w-100'
                        disabled={password.length < 5 || password !== repeat || submitted}
                >
                    {
                        submitted ?
                        <FormattedMessage id='settingPassword'/> :
                        <FormattedMessage id='setPassword'/>
                    }
                </Button>

                { error ? <p className='text-danger'>{error}</p>
                    : (password.length > 0 && password.length < 5 ? <p className='text-danger'>
                        <FormattedMessage id='passwordLength'/>
                    </p> : (
                    password.length > 0 && password !== repeat ? <p className='text-danger'>
                        <FormattedMessage id='passwordMismatch'/>
                    </p> : null
                )) }
            </Form>
        </OtherPage>
    );
}

PasswordPage.propTypes = {
    intl: PropTypes.object.isRequired
}

export default injectIntl(PasswordPage);
