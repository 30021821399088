import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';
import PasswordPage from './pages/other/PasswordPage';
import HomePage from './pages/dashboard/HomePage';
import LessonPage from './pages/dashboard/LessonPage';
import CustomerPage from './pages/dashboard/CustomerPage';
import GroupPage from './pages/dashboard/GroupPage';
import LoginPage from './pages/other/LoginPage';
import ForgotPasswordPage
    from './pages/other/ForgotPasswordPage';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/password" element={<PasswordPage/>}/>
                <Route path="/reset-password" element={<ForgotPasswordPage/>}/>

                <Route path="/" element={<HomePage/>}/>
                <Route path="/lessons" element={<LessonPage/>}/>
                <Route path="/customers" element={<CustomerPage/>}/>
                <Route path="/groups" element={<GroupPage/>}/>
            </Routes>
        </Router>
    );
}

export default App;
